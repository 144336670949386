import React, {useEffect, useState} from 'react';
import { DataGrid,  GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import { makeStyles } from "@material-ui/core/styles";
import {deleteRow, updateCellValue} from '../ducks/inputs'
import { useDispatch, useSelector } from "react-redux";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import HelpIcon from '@material-ui/icons/Help';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import {insertRow} from '../ducks/inputs'
import {v1} from 'uuid';
import { Button, Tooltip, TextField } from "@material-ui/core";
import axios from 'axios'
import fileDownload from 'js-file-download';
import { SheetJSFT } from "../utils/types";
import XLSX from "xlsx";
import {updateData} from "../ducks/inputs";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "./Alert";
import SettingDialog from './SettingDialog';
import Help from './Help';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      paddingLeft:"3%",
      paddingRight:"3%",
      color:'#d9d9d9',
      border: 'none',
      fontSize:"11px"
    },
    icon:{
      cursor:'pointer',
      width:"20px"
    },
    btn: {
      color: "#999999",
      fontSize:"9px"
    },
    toolbar:{
      height: "30px"
    }
  }));

export default function EditableTable(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const rows = useSelector((state) => state?.inputs?.values) || props.data;

  const sampleData = useSelector((state) => state?.inputs?.values);
  const [data, setData] = useState(sampleData);
  const [file, setFile] = useState();
  const [errorMsg, setErrorMsg] = useState(null);

  //Setting Dialog
  const [open, setOpen] = React.useState(false);
  const [helpOpen, setHelpOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleHelpClickOpen = () => {
    setHelpOpen(true);
  };

  const handleHelpClose = (value) => {
    setHelpOpen(false);
  };

  const columns = [
    {
        headerName: "\u270E Name",
        field: "Name",
        editable: true,
        ColumnResizeIcon:true,
        flex: 2,
        
    },
    {
      headerName: "\u270E Likelihood",
      field: "LH",
      type: "number",
      editable: true,
      flex:1,
      valueFormatter: (params) => {
        // const valueFormatted = Number(params.value).toLocaleString('en-GB', { style: 'percent' });
        const valueFormatted = (params.value*100).toFixed(1)+ '%';
        return valueFormatted;
      },
      sortable:false
    },
    {
        headerName: "\u270E Typical Impact",
        field: "TI",
        type: "number",
        editable: true,
        flex:1.5,
        valueFormatter: (params) => {
          const valueFormatted = Number(params.value).toLocaleString();
          return valueFormatted;
        },
    },
    {
      headerName: "\u270E Extreme but Plausible Impact",
      field: "EI",
      type: "number",
      editable: true,
      flex:1.5,
      valueFormatter: (params) => {
        const valueFormatted = Number(params.value).toLocaleString();
        return valueFormatted;
      },
    },
    {
      field: "",
      // flex:0.6,
      width: 35,
      sortable:false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          //TODO: Better handle last row removal!
          if (rows.length === 1){
            setErrorMsg({msg: "Atleast one input should stay!", severity:'error'});
            return;
          }
          dispatch(deleteRow(params.row.id));
          setErrorMsg({msg: "Row removed!", severity:'success'});
        };
        return <HighlightOffIcon className={classes.icon} onClick={onClick}/>;
      }
    },
];

function handleCellChange(e){
  // dispatch(updateCellValue({rowId:e.id, column: e.field, value:e.props.value}));
}
function handleCellChangeCommited(e){
  //TODO: Find a better way of input validation
  if(e.field === 'LH' && (e.props.value<0 || e.props.value>1)){
    setErrorMsg({msg:'Likelihood cannot be negative or larger than 1.', severity:'error'});
    dispatch(updateCellValue({rowId:e.id, column: e.field, value:0.01}));
  }
  else dispatch(updateCellValue({rowId:e.id, column: e.field, value:e.props.value}));
}

function handleAddRowClick(){
  const row = {"Name":"Insert...", "id":v1(),"LH":0.01, "TI":500000, "EI":1000000};
  console.log('Data state before insert', data);
  dispatch(insertRow([row]));
  setErrorMsg({msg:'Row added at the first position!', severity:'success'});
}

function handleDownload() {
  let url = process.env.PUBLIC_URL + '/sample_input.xlsx';
  let filename = 'sample_input.xlsx';
  axios.get(url, {
    responseType: 'blob',
  })
  .then((res) => {
    fileDownload(res.data, filename)
  })
}

function handleFile(file) {
  /* Boilerplate to set up FileReader */
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e) => {
      /* Parse data */
      try {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array", bookVBA: true });
          /* Get first worksheet */
          // console.log("Workbook", wb);
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // console.log("Worksheet", ws);
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws);
          const originalKeys = Object.keys(data[0]);
          console.log('Keys in Original Data', originalKeys, data);
          data.forEach(d => {
              d['Name'] = d[originalKeys[0]];
              d['LH'] = d[originalKeys[1]];
              d['TI'] = d[originalKeys[2]];
              d['EI'] = d[originalKeys[3]];
              d['id']= v1();

          });
          setData(data);
          // console.log('Data before Sending', data);
          dispatch(updateData(data));
      } catch (error) {
          setErrorMsg('Error! Make sure the order of columns is same as in the sample inputs.')
          // console.log("Wrong Input Format!", error);
      }
  };

  if (rABS) {
      reader.readAsBinaryString(file);
  } else {
      reader.readAsArrayBuffer(file);
  }
}
function handleChange(e) {
  const files = e.target.files;
  const ext = files[0]?.name?.split('.')?.slice(-1)[0]
  if (!["xlsx", "xls", "csv"].includes(ext)){
    setErrorMsg({msg:'Wrong file type! Please upload an Excel or CSV file.', severity:'error'})
    return;
  }
  if (files && files[0]) {
      handleFile(files[0]);
      setFile(files[0]);
      setErrorMsg({msg: 'Inputs uploaded successfully!', severity:'success'});
  }
}

function CustomToolbar() {
  return (
    <GridToolbarContainer className={classes.toolbar}>
      <Tooltip title="Please use it to upload your inputs. Do not modify the order of inputs!">
        <Button className={classes.btn} onClick={handleDownload}>
          <CloudDownloadIcon  className={classes.icon}/> Download Sample
        </Button>
      </Tooltip>
      <Button className={classes.btn}
        component="label"
      >
        <CloudUploadIcon className={classes.icon}/> 
        Upload Inputs
        <input
            type="file"
            hidden
            className="form-control"
            id="file"
            accept={SheetJSFT}
            onChange={handleChange}
        />
      </Button>
      <Button className={classes.btn} onClick={handleAddRowClick}>
        <AddIcon className={classes.icon}/>Insert Row
      </Button>
      <GridToolbarExport className={classes.btn} />
      <Button className={classes.btn} onClick={handleClickOpen}>
        <SettingsIcon className={classes.icon}/>Generic Inputs
      </Button>
      <Button className={classes.btn} onClick={handleHelpClickOpen}>
        <HelpIcon className={classes.icon} /> Help
      </Button>
    </GridToolbarContainer>
  );
}
  return (
    <div style={{height: 350,  width: '100%' }}>
      <DataGrid
        rows={rows} 
        columns={columns} 
        className={classes.root} 
        pageSize={10} 
        rowHeight={30} 
        autoPageSize={true}
        onEditCellChange={handleCellChange}
        onEditCellChangeCommitted={handleCellChangeCommited}
        disableColumnMenu={true}
        components={{
          Toolbar: CustomToolbar,
        }}
        />
        <Snackbar
              open={errorMsg !== null}
              anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
              }}
              autoHideDuration={5000}
              onClose={() => {
                  setErrorMsg(null);
              }}
          >
            <Alert severity={errorMsg?.severity}>{errorMsg?.msg}</Alert>
          </Snackbar>
          <SettingDialog
            open={open}
            onClose={handleClose}
          />
          <Help open={helpOpen} onClose={handleHelpClose}/>
    </div>
  );
}