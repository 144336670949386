export const UPDATE_CELL_VALUE = "UPDATE_CELL_VALUE";
export const UPDATE_DATA = "UPDATE_DATA";
export const INSERT_ROW = "INSERT_ROW";
export const DELETE_ROW = "DELETE_ROW";
export const UPDATE_GENERIC_INPUTS = "UPDATE_GENERIC_INPUTS";


export const updateCellValue = (payload) => {
    return { type: UPDATE_CELL_VALUE, payload };
};

export const updateData = (payload) => {
    return { type: UPDATE_DATA, payload };
};

export const insertRow = (payload) => {
    return { type: INSERT_ROW, payload };
};

export const deleteRow = (payload) => {
    return { type: DELETE_ROW, payload };
};

export const updateGenericInputs = (payload) => {
    return { type: UPDATE_GENERIC_INPUTS, payload };
};

const initialState = {
    count: 3,
    genericInputs: {
        cf:0.995,
        corr:0.50,
        extremeImpactPct: 0.95 
    },
    values: [
        {
            Name: "Delay on the main supplier",
            LH: 0.60,
            TI: 1680000,
            EI: 47040000,
            id: 0
        },
        {
            Name: "Delay on the main supplier's supplier",
            LH: 0.3,
            TI: 23520000,
            EI: 47040000,
            id: 1
        },
        {
            Name: "Risks about suppliers countries economic politic situation",
            LH: 0.10,
            TI: 5880000,
            EI: 17640000,
            id: 2
        },
        {
            Name: "Quality risks",
            LH: 0.15,
            TI: 3600,
            EI: 540000,
            id: 3
        },
        {
            Name: "Potential fault of the manufacturing line",
            LH: 0.20,
            TI: 4200000,
            EI: 12600000,
            id: 4
        },
        {
            Name: "Loss of key personnel",
            LH: 0.5,
            TI: 235200,
            EI: 705600,
            id: 5
        },
        {
            Name: "Problems due to deliveries on ports",
            LH: 0.5,
            TI: 672000,
            EI: 2016000,
            id: 6
        },
        {
            Name: "Risk from logistic service providers",
            LH: 0.04,
            TI: 336000,
            EI: 1008000,
            id: 7
        },
        {
            Name: "Overstock",
            LH: 0.05,
            TI: 882000,
            EI: 4410000,
            id: 8
        },
        {
            Name: "Retailer goes bankrupt",
            LH: 0.05,
            TI: 225000,
            EI: 900000,
            id: 9
        },
    ]
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_CELL_VALUE':
            const updatedValues = state.values.map(obj =>
                obj.id === action.payload.rowId ? { ...obj, [action.payload.column]: isNaN(Number(action.payload.value)) ? action.payload.value : Number(action.payload.value)  } : obj
            );
            return {...state, values: updatedValues}
        case 'UPDATE_DATA':
            return {...state, values: action.payload}
        case 'INSERT_ROW':
            const newValues = action.payload.concat(state.values);
            return {...state, values:newValues};
        case 'DELETE_ROW':
            const filteredvalues = state.values.filter(val => val.id != action.payload);
            return {...state, values:filteredvalues};
        case "UPDATE_GENERIC_INPUTS":
            return {...state, genericInputs:action.payload};
        default:
            return state;
    }
};