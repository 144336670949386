
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogTitle, TextField, DialogActions, Button, DialogContent, DialogContentText } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';

export default function Help(props) {
    const dispatch = useDispatch();
    console.log(props)
    const { onClose, open } = props;
  
    const handleClose = () => {
        onClose();
    };
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Help</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <InfoIcon width="20px"/> The columns marked with {'\u270E'} icon are editable!
          </DialogContentText>
          <DialogContentText>
            <InfoIcon width="20px"/> Cell values can be edited either by *Double Clicking* or pressing the *Enter* key on them!
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
  