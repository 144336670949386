import React from "react";
import { CardContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SmallBar from "./SmallBar"

const useStyles = makeStyles((theme)=>({
    root: {
        flexGrow: 1,
        color: '#d9d9d9',
        paddingTop:'9%'
    },
    title: {
        fontSize: 28,
        marginTop: "5%",
    },
    subTitle: {
        fontSize: 32,
        marginBottom: "10px",
        color: '#f7e53b',
        fontWeight: 'bold',
    },
}));

const barColors = ['#C0504D', '#5785cf'];

export default function Dashboard(props) {
    const classes = useStyles();
    const loss = [props.outputSummary.simpleSum, props.outputSummary.correlatedSum]
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <CardContent>
                        <Typography className={classes.title} gutterBottom>
                            Supply Chain VaR
                        </Typography>
                        <Typography className={classes.subTitle}>
                        {Math.round(props.outputSummary.correlatedSum).toLocaleString() || 0}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardContent >
                    </CardContent>
                    <CardContent >
                    </CardContent>
                    <SmallBar data={{labels: ["SIMP. SUM", "CORR. SUM"], values:loss}} title={'Simple vs. Correlated Sum'} barColor={[barColors[0], "#5785cf"]}/>
                </Grid>
            </Grid>
        </div>
    );
}
