import React, { Component, useEffect } from "react";
import Chart from "react-apexcharts";

const style = {
    chart: {
        fontFamily: `font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
       "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale`,
    },
};

export default function OutputChart(props) {
        const options = {
            series: [{
            name: 'VaR',
            type: 'column',
            data: props.outData.map((d) => d.VaR)
          }],
          chart: {
            foreColor: '#d9d9d9'
          },
        //   theme:{
        //     palette: 'palette2', 
        //   },
        colors: ['#C0504D', '#5785cf'],
          dataLabels: {
            enabled: false
          },
          title: {
            text: 'VaR',
            align: 'center',
            color: '#d9d9d9',
            style:{
                fontWeight: 'normal',
              }
          },
          xaxis: {
            categories: props.outData.map(function (d){
              if (d.Name.length > 25) return d.Name.substring(0,25) + ".";
              return d.Name.substring(0,25);
            })
          },
          yaxis: {
            title: {
              text: 'VaR',
            },
            labels: {
                formatter: (val) => { return Math.round(val).toLocaleString()},
            },
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return Math.round(val).toLocaleString()
              }
            }
          }
        };
    return (
        <div className={style.chart}>
            <Chart
                options={options}
                series={options.series}
                // width="100%"
                height={345}
            />
        </div>
    );
}